#container {
  pointer-events: none;
}

#frontRound {
  overflow: hidden;
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#circle1 {
  background-color: #F9D978;
  width: 5em;
  height: 5em;
  border-radius: 100%;
}

#rect1 {
  background-color: #043F8C;
  width: 5em;
  height: 5em;
  transform-box: fill-box;
  border-radius: 25%;
}

#whiteBanner {
  min-height: 92%; /*83%;*/
  width: 100%;
}

@media (min-width: 768px) {
  #whiteBanner {
    width: 95%;
  }
}

html,
body {
  height: 100%;
}
#bgImg {
  background-image: url('../../../assets/background.jpg');
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.googleButton {
  width: 100%;
  margin-top: 12px;
  background: white;
  border: 2px solid #eee;
  border-radius: 2px;
  padding: 12px 20px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  font-weight: bold;
  cursor: pointer;
}

.googleButton img {
  width: 1.2em;
  vertical-align: -2px;
}

.googleButton img {
  width: 1.2em;
  vertical-align: -2px;
}

.continueButton {
  margin-top: 12px;
  width: 100%;
}

.landingPane textCycle {
  font-size: 2.4rem;
}

.landingPane img {
  padding: 0px 10px 10px 10px;
}

.pane {
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.75);
  padding: 32px;
  width: 90%;
  max-width: 400px;
  border-radius: 4px;
  display: block;
  margin: 64px auto;
  text-align: left;
}

.pane h2 {
  line-height: 2rem;
  font-weight: 900;
  font-size: 2rem;
}

.pane .inputWithLabel {
  display: block;
  margin: 8px 0px;
  width: 100%;
}

.alternateDisclaimer {
  color: #333;
  font-size: 0.8rem;
  text-align: center;
  margin: 16px 0px 0px;
}

.alternateDisclaimer a {
  color: inherit;
}

@media only screen and (max-height: 500px) {
  #logo img {
    width: 10rem;
  }
}
