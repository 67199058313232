.dropdowncontent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;
}

.dropdowncontent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdowncontent a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.topbar {
 margin-left: 30%;
  width: 70%;
}
