.page {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url('../../assets/knowledge-background.jpg');
  background-position: center;
  background-size: cover;
  min-height: 100%;
  overflow: hidden;
}

.pane {
  background: white;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.25);
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  min-height: 100vh;
}
